import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import themeIncluia from 'theme/incluia';

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 43,
  height: 24,
  padding: 0,
  display: 'flex',
  borderRadius: 12,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(8px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        border: 'none'
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: 'transparent',
    border: '1px solid #bdbdbd',
    boxSizing: 'border-box',
  },
}));

const AndOrSwitch = ({ label, labelPlacement, ...props }) => {
  return (
      <FormControlLabel
        control={<CustomSwitch {...props } />}
        label={label}
        labelPlacement={labelPlacement}
        style={{margin: '2px 0px 0px 4px'}}
      />
  );
};

export default AndOrSwitch;
