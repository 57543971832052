import { Button, Box, Radio, styled, Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import { makeStyles } from '@material-ui/core';

// export const styles = makeStyles((theme) => ({
//   buttonAction: {
//     padding:'10px 24px 10px 24px',
//     borderRadius: '20px',
//     color:'#005F64 !important',
//     borderColor:'#005F64 !important',
//     height:'37px',
//     width:'126px',
//     '&:hover': {
//       borderColor:'#005F64 !important',
//     },
//   },
//   btnClose: {
//     borderRadius: 20,
//     color:'#005F64 !important',
//   },
// }));

export const MuiBoxAction = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 24
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #E0E0E0',
  borderRadius: 8,
  padding: 16
}));

export const MuiRadio = styled(Radio)(({ theme }) => ({
  width: 24,
  height: 24,
  mr: '12px'
}));

export const MuiBoxAlternative = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginLeft:12
}));

export const MuiBoxExpression = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: '#E0F7FA',
  display: 'inline-block',
  padding: '6px 12px',
  marginBottom: '5px',
}));

export const MuiButtonAction = styled(Button)(({ theme }) => ({
  padding:'10px 24px 10px 24px',
  borderRadius: '20px',
  color:'#005F64',
  borderColor:'#005F64',
  height:'37px',
  width:'126px',
  '&:hover': {
    borderColor:'#005F64',
  },
}));

export const MuiAlertValidate = styled(Alert)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
  color: '#000',
  padding: '0px 16px',
  display: 'flex',
  alignItems: 'center',
  '.MuiAlert-icon': {
    color: '#5c6bc0',
  }
}));

export const MuiCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color:`${localStorage.getItem('colorPrimary')}`,
  height:47,
  width:47
}));

export const descriptionStyle = {
  textAlign:'justify',
  fontWeight:400,
  color:'#000000'
}
