import {
  Accordion,
  AccordionDetails, Box, Checkbox,
  Divider, Grid, IconButton,
  styled, TableCell, TableHead,
  Typography
} from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Create,
  OpenInFull,
  PinDrop,
  Refresh,
  ThumbUp
} from '@mui/icons-material';
import { lighten } from '@mui/material/styles';

export const MuiDivRoot = styled('div')(({ theme }) => ({
  width: '100%',
  '&, & *': {
    '&::-webkit-scrollbar': {
      backgroundColor: '#ebeef2',
      height: 5,
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d3dbe5',
      borderRadius: 3,
    },
  },
}));

export const MuiDivAccordion = styled('div')(({ theme }) => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  width: '100%',
  borderRadius: '12px !important',
  border: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
  marginBottom: 16,
}));

export const MuiRecommendationsCandidatesHeader = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginBottom: 15.5,
  justifyContent: 'space-between'
}));

export const MuiDivHeader = styled('div')(({ theme }) => ({
  // borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
  textAlign: 'center',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '14.63px',
  letterSpacing: '0.4px',
  color: "#424242",
  // fontFamily: 'Montserrat',
  // fontWeight: 500,
  // fontSize: '12px',
}));

export const MuiAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  width: '100%',
  borderRadius: '12px !important',
  border: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
  marginBottom: 16,
}));

export const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  flexDirection: 'column'
}));

export const MuiAccordionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontFamily:'Montserrat',
  fontSize:'14px',
  color: '#424242',
}));

export const MuiRecommendationsCandidatesLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily:'Montserrat',
  letterSpacing: '0.1%',
  fontSize: 14,
  alignSelf: 'center',
  marginRight: 22,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
}));

export const MuiWidgetSubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  fontFamily:'Montserrat',
  lineHeight: '17.07px',
  letterSpacing: '0.1px',
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
}));

export const MuiRecomendationsVariables = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
}));

export const MuiCreateIcon = styled(Create)(({ theme }) => ({
  width: '18px !important',
  height: '18px !important',
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
}));

export const MuiRefreshIcon = styled(Refresh)(({ theme }) => ({
  width: '18px !important',
  height: '18px !important',
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
}));

export const MuiDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]
}));

export const MuiRecommendationsTableHeaderContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
}));

export const MuiTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`
}));

export const MuiTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  paddingLeft: 16,
  textAlign: 'center',
  verticalAlign: 'middle',
  // borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`
}));

export const muiHeaderDivider = {
  position: 'relative',
  height: '24px',
  verticalAlign: 'middle',
  display: 'inline-block',
  transform: "translateX(-16px)",
}

export const MuiTableCellItem = styled(TableCell)(({ theme }) => ({
  padding: 0,
  height: 52,
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '16px',
}));

export const MuiTableCellIcon = styled(TableCell)(({ theme }) => ({
  padding: 0,
  height: 52,
  position: 'relative',
}));

export const MuiCheckbox = styled(Checkbox)(({ theme }) => ({
  width: 18,
  height: 18,
  '&.Mui-checked':{
    color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  },
}));

export const MuiVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  fill: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
}));

export const MuiBoxIcon = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
  display: "flex",
  width: 40,
  height: 40,
  marginLeft:4,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const MuiOpenInFullIcon = styled(OpenInFull)(({ theme }) => ({
  width: 22,
  height: 20
}));

export const MuiThumbUpIcon = styled(ThumbUp)(({ theme }) => ({
  width: 22,
  height: 20
}));

export const MuiPinDropIcon = styled(PinDrop)(({ theme }) => ({
  width: 22,
  height: 20
}));

export const filterOverDemandContainerStyle = {
  marginTop: 16,
};

export const filterOverDemandContainerWithoutStyle = {
  marginTop: 0,
};

export const iconButtonHoverStyle = {
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
};
