import { Card, CardActionArea, Grid, styled, Typography } from '@mui/material';
import { hexToRgb } from '../../../../../../../../utils/colorConverter';
import { GLOBAL } from '../../../../../../../../utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiTitle = styled(Typography)(({ theme }) => ({
  marginTop: 10,
  fontSize: 16,
  fontWeight:700,
  fontFamily:'Raleway',
}));

export const MuiCard = styled(Card,  {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})(({ theme,active, disabled  }) => ({
  width: 180,
  height: 180,
  cursor: disabled ? 'auto' : 'pointer',
  borderStyle: 'solid',
  borderRadius:12,
  borderWidth: active ? '2px solid' : '0px',
  borderColor: active ? GLOBAL.COLOR_THEME.PRIMARY : theme.palette.grey[300],
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  margin: 0,
}));

export const MuiCardActionArea = styled(CardActionArea, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'active'
})(({ theme, disabled, active  }) => ({
  cursor: disabled ? 'auto' : 'pointer',
  background: active ? lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9) : '#F5F5F5',
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
}));

export const MuiGrid = styled(Grid, {
  shouldForwardProp: (prop) =>  prop !== 'active'
})(({ theme, active  }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: active ? lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.2) : '#616161',
  '& .MuiSvgIcon-root':{
    color: active ? `${lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.3)} !important` : '#616161',
  }
}));

export const commonIconsWidgetStyle = {
  width: 60,
  height: 60,
  color: '#616161',
  fontSize: 60,
}
