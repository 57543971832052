import { GLOBAL } from 'utils/constants';
import { styled, Toolbar } from '@mui/material';

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  margin: '0',
  width: '100%',
  zIndex: 1201,
}));

export const nonSelectedTab = {
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
  fontSize: 14,
  fontWeight: 500,
};

