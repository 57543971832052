import * as React from 'react';
import {useSelector} from 'react-redux';
import themeIncluia from 'theme/incluia';
import { MuiToolbar, nonSelectedTab } from './styles/widgetsTabs';
import Widgets from './Widgets';
import {GLOBAL} from 'utils/constants';
import {
  styled,
  Tab,
  Tabs,
  Grid,
  ThemeProvider,
  Paper,
  Container } from '@mui/material';
import { lighten } from '@mui/material/styles';

const WidgetsTabs = () => {

  const tabs = useSelector((state) => state.app.tabs);
  const [activeTab, setActiveTab] = React.useState('');


  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (tabs[0]) setActiveTab(tabs[0]?.id);
  }, [tabs]);

  const dinamicStyles = index => (index === activeTab ? {} : nonSelectedTab);
  const tabStyle = {
    minWidth: '25%',
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
    fontWeight: 500,
    fontSize: 14,
    fontFamily:'Montserrat',
  }

  const MuiTabs = styled(Tabs)(({ theme }) => ({
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '& .Mui-selected': {
      fontSize: 14,
      fontWeight: 500,
      color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[800],
      transition: 'font-size 10ms, font-weight 10ms',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
      height: 3,
      borderRadius: '100px 100px 0px 0px',
    },
  }));

  const MuiTab = styled(Tab)(({ theme }) => ({
    minWidth: 'auto',
    paddingLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
      transition: 'background-color 0.3s',
      borderRadius: '12px 12px 0px 0px',
    },
  }));



  return (
    <Container disableGutters fixed>
      {activeTab && (
        <>
          <ThemeProvider theme={themeIncluia}>
            <MuiToolbar disableGutters>
              <Grid container component={Paper} elevation={0} spacing={4}
                    style={{
                      margin: 0,
                      zIndex: 1201,
                      borderBottom: `1px solid ${GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[200]}`,
                      width: '100%',
                      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50],
                      borderRadius: '20px 0px 0px 0px',
                      paddingLeft: '10px'
                    }}>
                <MuiTabs
                  onChange={handleChange}
                  value={activeTab}
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'
                  orientation='horizontal'
                  indicatorColor='secondary'
                >
                  {tabs.map((w, index) => (
                    <MuiTab
                      label={w.name}
                      value={w.id}
                      key={w.id}
                      style={tabStyle}
                      sx={`${dinamicStyles(index)}`}
                    />
                  ))}
                </MuiTabs>
              </Grid>
            </MuiToolbar>
          </ThemeProvider>
          <Grid style={{
            maxHeight: 'calc(87vh - 48px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '3px'  // Adjust the width as needed
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'darkgrey',
              outline: '1px solid slategrey'
            }
          }}>
            <Widgets tabId={activeTab}/>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default WidgetsTabs;
