/* eslint-disable react/require-default-props */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useDataPolygonWidget from 'api/hooks/useDataPolygonWidget';
import {
  setViewState,
  selectSourceById,
  setFeatureSelectionMode,
  setFeatureSelectionEnabled,
  removeSpatialFilter,
  addSpatialFilter
} from 'lib/react-geo-tool/packages/react-redux/src/';
import { _FilterTypes as FilterTypes } from 'lib/react-geo-tool/packages/react-core/src/';
import WrapperWidgetUI from '../WrapperWidgetUI';
import WidgetWithAlert from '../sources-carto/WidgetWithAlert';

import PolygonWidgetUI from './PolygonWidgetUI';
import {
  setEnabledSelectPoint,
  setListPolygonWidgetForDelete,
  setUpdatePolygonWidget,
  setSelectedPointCoordinate,
  setLayerIsochrones,
  setPolygonDataLoading,
  setDrawedGeometry,
  setAsDeletedPolygon,
  setSelectWidgetId,
  setIsTooltipOnClick,
  setOnClick,
  setListFilterPolygonData
} from '../../../../store/appSlice';
import {useLocation} from "react-router";
import useDataIsochrone from '../../../../api/hooks/useDataIsocrona';
import IsochroneModal from './IsochroneModel';
import useDataPolygonDrawed from 'api/hooks/useDataPolygonDrawed';
import { getPolygon } from 'lib/react-geo-tool/packages/react-widgets/src/models';
import useWidgetFetch from 'lib/react-geo-tool/packages/react-widgets/src/hooks/useWidgetFetch';
import { useAuth } from 'components/providers/AuthProvider';
import { addFilter, removeFilter } from "@carto/react-redux";
import isEqual from "lodash/isEqual";
import useGenerateDynamicRecommendationsName
  from "../../../../api/hooks/useGenerateDynamicRecommendationsName";
import {useLayers} from "../../../providers/LayersProvider";

/**
 * Renders a <ListWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 */

function PolygonWidget(props) {
  const dispatch = useDispatch();

  const {
    id,
    title,
    datasetName,
    wrapperProps,
    dataSource,
    columns,
    global,
    noDataAlertProps,
    droppingFeaturesAlertProps,
    update,
  //  circuchroneTable,
    circuchroneTableID
  } = props;
  const source = useSelector((state) => selectSourceById(state, id) || {});
  const { filters } = source;
  const open = useSelector((state) => state.app.enabledSelectPoint);
  const selectedPointCoordinate = useSelector((state) => state.app.selectedPointCoordinate);
  const drawedGeometry = useSelector((state) => state.app.drawedGeometry);
  const selectionMode = useSelector((state) => state.carto.featureSelectionMode);
  const featureSelectionEnabled = useSelector((state) => state.carto.featureSelectionEnabled)
  const enabledSelectPoint = useSelector((state) => state.app.enabledSelectPoint)
  const layerIsochrones = useSelector((state) => state.app.layerIsochrones)
  const updatePolygonWidget = useSelector((state) => state.app.updatePolygonWidget)
  const [action, setAction] = React.useState('');
  const [polygonId, setPolygonId]= React.useState();

  const [isochrone, setIsochrone ] = React.useState(null);

  const location = useLocation();
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const mapid = splittedPathname[6];
  const widget = useSelector((state) => state.app.widgets.find(w => w.id === id));
  const color = widget ? widget.params.layerColor: null;
  const widgetSelect = useSelector((state) => state.app.selectWidgetId);
  const listPolygonWidgetForDelete = useSelector((state) => state.app.listPolygonWidgetForDelete);
  const filterGeometries = useSelector((state) => state.carto.spatialFilter?.geometry?.coordinates);
  const layers = useSelector((state) => state.app.layers);
  const { changeVisible} = useLayers();
  const { user } = useAuth();
  const spatialFilter = useSelector((state) => state.carto.spatialFilter);
  const filteredData = useSelector((state) => state.app.listFilterPolygonData);
  const enabled = useSelector((state) => state.carto.featureSelectionEnabled);


  const columnsToFetch = columns.map(col => {
    if (col.calculator) {
      return { ...col, formula: col.field };
    } else if (!col.calculator) {
      return { ...col, alias: col.uniqueName==undefined? col.field : col.uniqueName };
    } else {return { ...col, alias: col.field };}
  });

  const {
    data: listData,
    isLoading,
  } = useWidgetFetch(getPolygon, {
    id,
    dataSource: id,
    params: {
      user_id: user.id,
      columns: [
          ...columnsToFetch,
          ...['geom'].map((c) => ({
            name: c,
            visible: false,
          })),
        ]
    },
    global,
    attemptRemoteCalculation: global
  });

  React.useEffect(() => {
    if (selectionMode == 'IsochroneFromMapMode' && enabled) {
      // dispatch(setFeatureSelectionEnabled(false));
      dispatch(setSelectedPointCoordinate(null));
      dispatch(setEnabledSelectPoint (true));
      // dispatch(setSelectWidgetId(widget.id));
    }
  },[selectionMode])

  React.useEffect(() => {
    if (update) {
      refreshPolygonWidgetData();
      dispatch(setUpdatePolygonWidget(false));
    }
  }, [update])

  React.useEffect(() => {
    dispatch(
      removeFilter({
        id: id,
        column: 'geom',
      })
    );
  }, [filters])

  const dispatchSetLayerIsochrones = (data) => {
    dispatch(setLayerIsochrones(data));
  }

  const dispatchSetPolygonDataLoading = (data) => {
    dispatch(setPolygonDataLoading(data));
  }

  const {
    mutateAsync: saveIsochrone,
    isSuccess: isSuccessSaveIsochrone,
    isError: isErrorSaveIsochrone,
    error: errorSaveIsochrone,
    isLoading: isLoadingSaveIsochrone,
  } = useDataIsochrone(dispatchSetLayerIsochrones, dispatchSetPolygonDataLoading);

  const {
    mutateAsync: saveDrawed,
  } = useDataPolygonDrawed();

  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));

  const handleSelect = React.useCallback(
    ({ lat: latitude, lon: longitude }) => {
      let counter = 1;

      if (zoomLevel > 14) {
        dispatch(
          setViewState({
            zoom: 14,
          })
        );
      }

      if (zoomLevel > 12) {
        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 12,
            })
          );
        }, counter * 550);
        counter += 1;
      }

      if (zoomLevel > 10) {
        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 10,
            })
          );
        }, counter * 575);
        counter += 1;
      }

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 12,
            latitude,
            longitude,
          })
        );
      }, counter * 600);
      counter += 1;

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 14,
            latitude,
            longitude,
          })
        );
      }, counter * 625);
      counter += 1;

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 16,
            latitude,
            longitude,
          })
        );
      }, counter * 650);
      counter += 1;
    },
    [dispatch, zoomLevel]
  );

  React.useEffect(() =>{
    if(featureSelectionEnabled ) {
     dispatch(setIsTooltipOnClick(false))
     dispatch(setOnClick(null))

    }
      else if (!drawedGeometry) {
      dispatch(setOnClick(null))
      dispatch(setIsTooltipOnClick(true))

      }

  }, [featureSelectionEnabled, drawedGeometry])
  React.useEffect(() =>{
    if(enabledSelectPoint) {
     dispatch(setIsTooltipOnClick(false))
     dispatch(setOnClick(null))

    }
      else if (updatePolygonWidget){
        dispatch(setIsTooltipOnClick(true))
        dispatch(setOnClick(null))
      }


  }, [enabledSelectPoint, updatePolygonWidget])

  const handleEditIsochone = (data) => {
     if (data.row.polygon_type == "isochrone"){
      setIsochrone({
        coordinate: data.row.geom,
        ...data.row
      });
      setAction('update');
      dispatch(setSelectedPointCoordinate(null));
      dispatch(setEnabledSelectPoint (true));
     } else if (data.row.polygon_type == "drawn") {
      setAction('update');
      dispatch(setFeatureSelectionEnabled(true));
      setPolygonId(data.row.polygon_id) ;
     }
  }

  React.useEffect(() => {
    if (selectedPointCoordinate){
        setIsochrone({ coordinate: selectedPointCoordinate})
    }
  }, [selectedPointCoordinate]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setEnabledSelectPoint (true);
      dispatch(setFeatureSelectionEnabled(false));
      dispatch(setFeatureSelectionMode('DrawPolygon'))
    } else {
      dispatch(setEnabledSelectPoint (false));
      dispatch(setSelectedPointCoordinate(null));
      dispatch(setFeatureSelectionEnabled(false));
      dispatch(setFeatureSelectionMode('DrawPolygon'))
    }
  }

  React.useEffect(()=>{
    if (id === widgetSelect) {
      saveOnSubmitDrawed(action);
      setAction(null);
    }
  }, [drawedGeometry, id, widgetSelect]);

  const widgets = useSelector((state) => state.app.widgets);
  const saveOnSubmitIsochrone = async (data ) => {
    let iscData;
    if(data.action === 'update' ) {
      iscData = {
        polygonId: data.isochrone.polygon_id,
        coordinate: data.isochrone.coordinate,
        mode: data.routingProfile,
        timeMin: data.time,
        generalize: data.isRural,
        mapId : mapid,
        widgetId: widgetSelect,
        polygonType: 'isochrone',
        layerColor: color,
        distanceKm: data.distanceKm / 1000,
        isochroneType: data.isochroneType,
        circuchroneTable: data.circuchroneTable
      }
    } else {
      const widget = widgets.filter((el) => el.id === widgetSelect)
      const circuchroneTable = widget[0].params.circuchroneTable
      const cartoName = circuchroneTable == '' || circuchroneTable == undefined ? null : circuchroneTable[0].cartoName
      iscData = {

        coordinate: selectedPointCoordinate,
        mode: data.routingProfile,
        timeMin: data.time,
        generalize: data.isRural,
        mapId : mapid,
        widgetId: widgetSelect,
        polygonType: 'isochrone',
        layerColor: color,
        distanceKm: data.distanceKm / 1000,
        isochroneType: data.isochroneType,
        circuchroneTable: cartoName
      }
    }

    dispatch(setEnabledSelectPoint (false));
    dispatch(setSelectedPointCoordinate(null));
    setAction('');
    await saveIsochrone({type: data.action, iscData: iscData});
    dispatch(setFeatureSelectionEnabled(false));
    dispatch(setFeatureSelectionMode('DrawPolygon'))
    // dispatch(setSelectWidgetId(id));
  }

  const updateListForDelete = (elements) => {
    let selection = [];
    elements?.map( item => {
     const data = {
       polygon_id : listData.values[item].polygon_id,
       polygon_type: listData.values[item].polygon_type,
       user_deletions: listData.values[item].user_deletions,
       mapId : mapid,
       widgetId: id,
       geometry: listData.values[item].geometry
      }
      selection.push(data);
    });
    dispatch(setListPolygonWidgetForDelete({id,selection}));
    dispatch(setSelectWidgetId(id));
  }
  const updateAsDeletePolygon = (data) => {
    dispatch(setAsDeletedPolygon(data));
  }

  const saveOnSubmitDrawed = React.useCallback(async (data ) => {
    let drawedData;
    if(data === 'update' ) {
      dispatch(setPolygonDataLoading(true));
      drawedData = {
        mapId: mapid,
        widgetId: id,
        geometry: drawedGeometry,
        polygonId: polygonId,
      }
      await saveDrawed({action: data, data: drawedData});

      refreshPolygonWidgetData();

      dispatch(setDrawedGeometry(null));
      dispatch(setPolygonDataLoading(false));
      dispatch(setFeatureSelectionMode('DrawPolygon'))
    } else if (drawedGeometry){
      dispatch(setPolygonDataLoading(true));
      drawedData = {
        mapId: mapid,
        widgetId: id,
        geometry: drawedGeometry,
      }
      await saveDrawed({action: 'create', data: drawedData});

      refreshPolygonWidgetData();

      dispatch(setDrawedGeometry(null));
      dispatch(setPolygonDataLoading(false));
      dispatch(setFeatureSelectionMode('DrawPolygon'))
    }
  });

  const refreshPolygonWidgetData = () => {
    dispatch(
      addFilter({
        id: id,
        column: 'geom',
        type: FilterTypes.STRING_SEARCH,
        values: 'POLYGON',
      })
    );
  };
  const handleFilterPolygon = () => {
    const geometries = [];
    let temp= [];
    if (widgetSelect || listPolygonWidgetForDelete[widget.id]) {
      temp = listPolygonWidgetForDelete[widget.id]
    }
    temp?.forEach(element => {
      if (element.geometry['coordinates'].length !=1){
        geometries.push(...element.geometry['coordinates']);
      }else{
        geometries.push([...element.geometry['coordinates']]);
      }
    });
    let equal = isEqual(geometries, filterGeometries);
    if (equal){
      dispatch(removeSpatialFilter());
      dispatch(setListPolygonWidgetForDelete({id:widget.id, selection:[]}));
      dispatch(setAsDeletedPolygon(true));
      dispatch(setSelectWidgetId());
      dispatch(setListFilterPolygonData({}));
      updateListFilter([]);
    }
    else if(geometries.length > 0 && !equal){
      const geometry = { geometry:{type: "Feature" , properties: {},
          geometry:{type:"MultiPolygon" ,
            coordinates: geometries}}};
      dispatch(setSelectWidgetId(widget.id));
      dispatch(addSpatialFilter(geometry));
      updateListFilter(temp);
    }else{
      dispatch(removeSpatialFilter());
      dispatch(setSelectWidgetId());
      dispatch(setListFilterPolygonData({}));
    }

    if (true) {
      let prevStateLayers = layers.filter(layer => layer.visible===true);
      prevStateLayers.forEach((layer) => {
        if (layer.geometry === 'Point'){
          changeVisible(`${layer.id}-pointCircle`, false , layer.isRecommendation);
          changeVisible(layer.id, false , layer.isRecommendation);
        } else changeVisible(layer.id, false , layer.isRecommendation);
        setTimeout(() => {
          if (layer.geometry === 'Point'){
            changeVisible(`${layer.id}-pointCircle`, true , layer.isRecommendation);
            changeVisible(layer.id, true, layer.isRecommendation);
          } else  changeVisible(layer.id, true, layer.isRecommendation);
        }, 50);
      });
    }
  }
  const updateListFilter = (element) => {
    const data = {};
    element?.forEach(selection => {
      const {widgetId, mapId, polygon_id} = selection;
      const key = `${widgetId}-${mapId}`;
      if (!data[key]) {
        data[key] = {widgetId, mapId, polygons: [polygon_id], type: 'polygon'};
      } else {
        if (!data[key].polygons.includes(polygon_id)) {
          data[key].polygons.push(polygon_id);
        }
      }
    });
    dispatch(setListFilterPolygonData(data));
  }
  const key = `${widgetSelect}-${(location.pathname.split('/'))[6]}`;
  React.useEffect(() => {
    if(spatialFilter && filteredData[key]?.widgetId == widgetSelect && widget.id == widgetSelect) {
      handleFilterPolygon();
    }
  },[listPolygonWidgetForDelete]);

  return (
  <WrapperWidgetUI title={title} widgetId={id} isLoading={isLoading} {...wrapperProps} >
    <WidgetWithAlert
      dataSource={dataSource}
      global={global}
      droppingFeaturesAlertProps={droppingFeaturesAlertProps}
      noDataAlertProps={noDataAlertProps}
    >
      <PolygonWidgetUI
        data={listData}
        newColumns={columns}
        id={id}
        onSelect={handleSelect}
        handleEdit={handleEditIsochone}
        updateListForDelete={updateListForDelete}
        updateAsDeletePolygon={updateAsDeletePolygon}
        handleFilterPolygon={handleFilterPolygon}
      />
    </WidgetWithAlert>
    {(selectedPointCoordinate && open) && (
      <IsochroneModal
        open={open}
        isochrone={isochrone}
        id={id}
        action={action ==='update' ? 'update' : 'create' }
        handleClose={handleClose}
        saveOnSubmitIsochrone={saveOnSubmitIsochrone}
      />
    )}

  </WrapperWidgetUI>
  );
}

PolygonWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  global: PropTypes.bool,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object,
};

PolygonWidget.defaultProps = {
  tooltip: true,
  wrapperProps: {},
  global: false,
  noDataAlertProps: {}
};

export default PolygonWidget;
