import * as React from 'react';
import { Divider, Grid } from '@mui/material';
import {AggregationTypes} from 'lib/react-geo-tool/packages/react-core/src/';
import {useDispatch, useSelector} from 'react-redux';
import {addSpatialFilter, removeFilter, removeSpatialFilter, setFeaturesReady} from 'lib/react-geo-tool/packages/react-redux/src/';
import {
  setAsDeletedRecommendation,
  setListFilterPolygonData,
  setListRecommendationsWidgetForDelete,
  setPolygonDataLoading,
  setSelectWidgetId,
  setWidgetColor
} from 'store/appSlice';
import 'tippy.js/dist/tippy.css';
import ListWidget from 'components/common/widgets/list/ListWidget';
import StatisticsWidget
  from '../../../../../common/widgets/statistics/StatisticsWidget';
import PolygonWidget from '../../../../../common/widgets/polygon/PolygonWidget';
import CategoryWidget from '../../../../../common/widgets/category/CategoryWidget';
import HistogramWidget from '../../../../../common/widgets/histogram/HistogramWidget';
import RecommendationsWidget from '../../../../../common/widgets/recommendations/RecommendationsWidget';

import {PieWidget} from 'lib/react-geo-tool/packages/react-widgets/src';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import { InfoOutlined } from '@mui/icons-material';
import useDataIsochrone from '../../../../../../api/hooks/useDataIsocrona';
import isEqual from 'lodash/isEqual';
import { useLayers } from 'components/providers/LayersProvider';
import { useAuth } from 'components/providers/AuthProvider';
import useGenerateDynamicRecommendationsName from 'api/hooks/useGenerateDynamicRecommendationsName';
import ParametersWidget
  from "../../../../../common/widgets/parameters/ParametersWidget";

const getTicks = (widget) => {
  const histColors = widget.params.customHistogramColors;

  if (histColors) {
    const n = histColors.length;
    return histColors.slice(0, n - 1).map((d) => {
      return +d.end;
    });
  }

  return [0, 10, 100, 1000, 10000];
};

const getDataAxis = (widget) => {
  const histColors = widget.params.customHistogramColors;

  if (histColors) {
    return histColors.map((d) => {
      return +d.end;
    });
  }

  return [0, 10, 100, 1000, 10000];
};

const Widget = ({widget, source, disabledWidget}) => {
  const categoryWidgetsColors = useSelector((state) => state.app.categoryWidgets);
  const updatePolygonWidget = useSelector((state) => state.app.updatePolygonWidget);
  const listRecommendationsWidgetForDelete = useSelector((state) => state.app.listRecommendationsWidgetForDelete);
  const filterGeometries = useSelector((state) => state.carto.spatialFilter?.geometry?.coordinates);
  const activeWidget = useSelector((state) => state.app.selectWidgetId);
  const dispatch = useDispatch();
  const spatialFilter = useSelector((state) => state.carto.spatialFilter);
  const filteredData = useSelector((state) => state.app.listFilterPolygonData);

  React.useEffect(() => {
    if (!widget.visible) {
      dispatch(removeFilter({id: widget.layer, column: widget.params.field}));
    }
    return () => {
      dispatch(removeFilter({id: widget.layer, column: widget.params.field}));
    };
  }, [widget.visible]);


  const widgetColor = useSelector((state) => state.app.widgetColor);
  const colorEnabled = widgetColor && widget.id === widgetColor.widgetId;
  const layer = useSelector((state) => state.app.layers.find((d) => d.id === widget.layer));
  const layers = useSelector((state) => state.app.layers);
  const { changeVisible} = useLayers();

  const datasetName = layer ? layer.datasetName : '';
  const [activeIconColor, setActiveIconColor] = React.useState(false);
  let categoryColors = React.useRef([]);
  const { user } = useAuth();

  const dispatchSetPolygonDataLoading = (data) => {
    dispatch(setPolygonDataLoading(data));
  }

  const {
    mutateAsync: DeleteIsochrone,
    isSuccess: isSuccessSaveIsochrone,
    isError: isErrorSaveIsochrone,
    error: errorSaveIsochrone,
    isLoading: isLoadingSaveIsochrone,
  } = useDataIsochrone(null, dispatchSetPolygonDataLoading);

  const onAutoStyle = (w) => {
    const tmp = !activeIconColor;
    setActiveIconColor(tmp);

    let value;
    let isHistogram = false;
    if (w.type === 'histogram') {
      isHistogram = true;
      value = w.params.customHistogramColors;
    } else {
        if (w.type === 'category') {
          value = w.colorType === 'simple' ? w.params.categoryColor :  w.params.customCategoryColors;
          if (w.colorType !== 'simple') {
            if (widget.params?.customCategoryColors[0]?.value.includes(' - ')) {
              const categoryWidgetColor = categoryWidgetsColors?.find(({widgetId}) => w.id === widgetId );
              let mappedCategoryColors = categoryWidgetColor?.customCategoryColors.map(({ name }) => {
                let categoryColorIntervalRelated = w.params.customCategoryColors.find(categoryColorInterval => {
                  const interval = categoryColorInterval.value.split(' - ');
                  return name >= Number(interval[0]) && name <= Number(interval[1]);
                });
                  if (categoryColorIntervalRelated || name == '__rest__') {
                    return {
                      color: categoryColorIntervalRelated ? categoryColorIntervalRelated.color : w.params.categoryColor,
                      value: name.toString()
                    };
                  }
              });
              if (mappedCategoryColors) {
                categoryColors.current = mappedCategoryColors;
              }
            } else {
              categoryColors.current = widget.params.customCategoryColors;
            }
          }
        }
      }

    const wColor = {
      widgetId: w.id,
      widgetType: w.type,
      //column: checkCaseStructure(w) ? w.params.alias : widget.params.field,
      column: w.params.calculator ? w.params.alias : w.params.field,
      type: isHistogram ? 'by_value' : w.colorType,
      simpleColor: w.params.categoryColor,
      colors: value,
    };

    dispatch(setWidgetColor({layer: w.layer, widgetColor: wColor}));

  };

  const noDataAlertObject = {
    title: 'No data',
    body: 'Please check you search filter'
  };

  const updateListFilter = (element) => {
     const data = {};
     if (widget.type == 'polygon'){
      element?.forEach(selection => {
        const { widgetId, mapId, polygon_id } = selection;
        const key = `${widgetId}-${mapId}`;
        if (!data[key]) {
          data[key] = { widgetId, mapId, polygons: [polygon_id], type: 'polygon' };
        } else {
          if (!data[key].polygons.includes(polygon_id)) {
           data[key].polygons.push(polygon_id);
         }
         }
      });
     }else{
      element?.forEach(selection => {
        const { widgetId, mapId, recommendation_id } = selection;
        const key = `${widgetId}-${mapId}`;
        if (!data[key]) {
          data[key] = { widgetId, mapId: useGenerateDynamicRecommendationsName( widget?.params?.isGlobal ), polygons: [recommendation_id], type: 'recommendation' };
        } else {
          if (!data[key].polygons.includes(recommendation_id)) {
           data[key].polygons.push(recommendation_id);
         }
         }
      });
     }
    dispatch(setListFilterPolygonData(data));
   }

   const handleFilterRecommendations = () => {
    const geometries = [];
    let temp= [];

    if (activeWidget || listRecommendationsWidgetForDelete[widget.id]) {
      temp = listRecommendationsWidgetForDelete[widget.id];
    }
    temp?.forEach(element => {
      if (element.geometry['coordinates'].length !=1){
        geometries.push(...element.geometry['coordinates']);
      }else{
        geometries.push([...element.geometry['coordinates']]);
      }
    });
    let equal = isEqual(geometries, filterGeometries);
    if (equal){
      dispatch(removeSpatialFilter());
      dispatch(setListRecommendationsWidgetForDelete({id:widget.id, selection:[]}));
      dispatch(setAsDeletedRecommendation(true));
      dispatch(setSelectWidgetId());
      dispatch(setListFilterPolygonData({}));
      updateListFilter([]);
    }
    else if(geometries.length > 0 && !equal){
      const geometry = { geometry:{type: "Feature" , properties: {},
        geometry:{type:"MultiPolygon" ,
                  coordinates: geometries}}};
        dispatch(setSelectWidgetId(widget.id));
        dispatch(addSpatialFilter(geometry));
        updateListFilter(temp);
    }
    else{
      dispatch(removeSpatialFilter());
      dispatch(setListFilterPolygonData({}));
      dispatch(setSelectWidgetId());
    }
    let prevStateLayers = layers.filter(layer => layer.visible===true);
    prevStateLayers.forEach((layer) => {
      changeVisible(layer.id, false , layer.isRecommendation);
      setTimeout(() => {
        changeVisible(layer.id, true , layer.isRecommendation);
      }, 50);
    });
   }

const key = `${activeWidget}-${(location.pathname.split('/'))[6]}`;

React.useEffect(() => {
  if(spatialFilter && filteredData[key]?.widgetId == activeWidget && widget.id == activeWidget) {
    handleFilterRecommendations();
  }
},[listRecommendationsWidgetForDelete]);

const filterInfoBtnIfNoTooltip = ( parameters  ) => {
  const { button, buttonId} = parameters;
  if (button.id !== buttonId || widget.description) {
    return true;
  }
  if ( !widget.description ) {
    return false;
  }
}

const filterRecommendationsWidgetBtns = (button) => {
  if ( button.id === 'a2' && widget.params.isGlobal && ( user.type === 'admin' || user.type === 'super_admin') ){
    return true
  }

  if ( button.id === 'a2' && widget.params.isGlobal && (user.type !== 'admin' || user.type !== 'super_admin') ) {
    return false
  }

  if ( widget.folded_state ? button.id === 'a0' : true )

  return true;
}
  return (
    <>
      {widget.type === 'histogram' && (
        <>
          <HistogramWidget
            id={widget.id}
            title={widget.name}
            dataSource={source}
            column={widget.params.field}
            operation={AggregationTypes.COUNT}
            ticks={getTicks(widget)}
            formatter={(v) => Number(v).toFixed(2)}
            dataAxis={getDataAxis(widget)}
            color={{
              enabled: colorEnabled,
              type: 'by_value',
              customColors: widget.params.customHistogramColors,
            }}
            global={true}
            noDataAlertProps={noDataAlertObject}
            description='not_description'
            wrapperProps={{
              description: widget.description,
              expand: !widget.folded_state,
              disabledWidget,
              actions: [
                {
                  id: 'a1',
                  icon: <InvertColorsIcon  color={ widgetColor?.widgetId === widget.id ? "primary" : "gray" }/>,
                  action: () => onAutoStyle(widget),
                  disabled: true
                },
                {
                  id: 'a0',
                  icon:  <InfoOutlined color='primary' />,
                  tooltip: {text: widget.description},
                  active: false,
                  type: 'info',
                },
              ].filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a0' }) ),
            }}
          />
          <Divider/>
        </>
      )}
      {widget.type==='parameters'&&(
        <>
          <ParametersWidget
          id={widget.id}
          type={widget.type}
          title={widget.name}
          wrapperProps={
            {
              expand: !widget.folded_state,
              description: widget.description,
              disabledWidget:false,
              actions: [
                {
                  id: 'a0',
                  icon:  <InfoOutlined color='primary' />,
                  tooltip: {text: widget.description},
                  active: false,
                  type: 'info',
                },
              ].filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a0' }) ),
            }
          }
          />
          <Divider/>
        </>
      )}
      {widget.type === 'category' && (
        <>

          <CategoryWidget
            id={widget.id}
            title={widget.name}
            dataSource={source}
            column={widget.params.field}
            operation={AggregationTypes.COUNT}
            order={widget.params.order}
            colorInMap={widget.params.colorInMap}
            global={true}
            formatter={(v) => Math.round(v)}
            description={widget.description}
            noDataAlertPropyarn ={noDataAlertObject}
            type={widget.type}
            wrapperProps={{
              expand: !widget.folded_state,
              description: widget.description,
              disabledWidget,
              actions: [
                {
                  id: 'a1',
                  icon: <InvertColorsIcon  color={ widgetColor?.widgetId === widget.id ? "primary" : "gray" }/>,
                  action: () => onAutoStyle(widget),
                  active: widget?.params?.colorInMap === undefined ? false : !widget.params.colorInMap,
                },
                {
                  id: 'a0',
                  icon:  <InfoOutlined color='primary' />,
                  tooltip: {text: widget.description},
                  active: false ,
                  type: 'info',
                },
               ].filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a0' }) ),
              }}
            color={{
              enabled: colorEnabled,
              type: widget.params.colorType,
              simpleColor: widget.params.categoryColor,
              customColors: categoryColors.current
            }}
            />
          <Divider/>
        </>
      )}
      {widget.type === 'pie' && (
        <PieWidget
          id={widget.id}
          title={widget.name}
          dataSource={source}
          column={widget.params.field}
          operation={AggregationTypes.COUNT}
          global={true}
          noDataAlertProps={noDataAlertObject}
          description={widget.description}
          wrapperProps={{
            expand: !widget.folded_state,
            description: widget.description,
            disabledWidget,
          }}
        />
      )}
      {widget.type === 'list' && (
        <ListWidget
          id={widget.id}
          title={widget.name}
          column={widget.params.field}
          columns={widget.params.list}
          datasetName={datasetName}
          dataSource={source}
          description={widget.description}
          global={true}
          noDataAlertProps={noDataAlertObject}
          wrapperProps={{
            expand: !widget.folded_state,
            description: widget.description,
            disabledWidget,
            actions: [
              {
                id: 'a0',
                icon:  <InfoOutlined color='primary' />,
                tooltip: {text: widget.description},
                active: false,
                type: 'info',
              },
            ].filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a0' }) ),
          }}
        />
      )}
      {widget.type === 'statistics' && (
        <StatisticsWidget
          id={widget.id}
          title={widget.name}
          columns={widget.params.fields}
          datasetName={datasetName}
          dataSource={source}
          description={widget.description}
          global={true}
          noDataAlertProps={noDataAlertObject}
          wrapperProps={{
            expand: !widget.folded_state,
            description: widget.description,
            disabledWidget,
            actions: [
              {
                id: 'a0',
                icon:  <InfoOutlined color='primary' />,
                tooltip: {text: widget.description},
                active: false,
                type: 'info',
              },
            ].filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a0' }) ),
          }}
        />
      )}
      {widget.type === 'polygon' && (
        <>
            <PolygonWidget
              id={widget.id}
              title={widget.name}
              column={widget.params.field}
              columns={widget.params.list || []}
              datasetName={datasetName}
              dataSource={source}
              description={widget.description}
              global={true}
              noDataAlertProps={noDataAlertObject}
              update={updatePolygonWidget}
              wrapperProps={{
                expand: !widget.folded_state,
                description: widget.description,
                disabledWidget: false,
                actions: [
                  {
                    id: 'a4',
                    icon:  <InfoOutlined color='primary' style={{width: '16px !important', height: '16px !important'}} />,
                    tooltip: {text: widget.description},
                    active: false,
                    type: 'info',
                  }
                ].filter( button => widget.folded_state ? button.id === 'a4' : true ).filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a4' }) ),
              }}
            />
        </>
      )}
      {widget.type === 'recommendations' && (
        <RecommendationsWidget
          id={widget.id}
          title={widget.name}
          column={widget.params.field}
          columns={widget.params.list ? widget.params.list : []}
          datasetName={datasetName}
          dataSource={source}
          description={widget.description}
          global={true}
          noDataAlertProps={noDataAlertObject}
          customData={{ ...widget.params, isRecommendationFormHidden: false }}
          wrapperProps={{
            expand: !widget.folded_state,
            description: widget.description,
            disabledWidget: false,
            actions: [
              {
                id: 'a0',
                icon:  <InfoOutlined color='primary' />,
                tooltip: {text: widget.description},
                active: false,
                type: 'info',
              },
            ].filter(filterRecommendationsWidgetBtns).filter( ( button ) => filterInfoBtnIfNoTooltip( {button, buttonId: 'a0' }) ),
          }}
        />
      )}
    </>
  );
};

const Widgets = ({tabId}) => {
  const layers = useSelector((state) => state.app.layers);
  const widgets = useSelector((state) => state.app.widgets);
  if (widgets?.length) {
    const visibleLayers = layers.reduce((acc, l) => ({
      ...acc,
      [l.id]: l.visible
    }), {});
    return widgets?.map((w) => {
      const showDisplayWidget = w.active_tab === tabId && w.visible;
      return (
        <Grid
          key={w.id}
          style={{
            display: showDisplayWidget ? 'inline' : 'none',
          }}
        >
          {w.visible && (
            <Widget source={w.layer}
                    widget={w}
                    disabledWidget={!visibleLayers[w.layer]}
            />
          )}
        </Grid>
      );

    });
  }
  return null;
};

export default Widgets;
